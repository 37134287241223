// overlay
.overlay-loader {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 0%;
      left: 0%;
      background-color: #fff;
      opacity: 0.8;
      width: 100%;
      height: 100%;
      z-index: 100;
    }
  
    &:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      margin: -1.5em 0em 0em -1.5em;
      width: 3em;
      height: 3em;
      // -webkit-animation: overlay-loader-spin 0.6s linear;
      // animation: overlay-loader-spin 0.6s linear;
      // -webkit-animation-iteration-count: infinite;
      // animation-iteration-count: infinite;
      // border-radius: 500rem;
      // border-color: #6c757d rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
      // border-style: solid;
      // border-width: 0.2em;
      -webkit-box-shadow: 0px 0px 0px 1px transparent;
      box-shadow: 0px 0px 0px 1px transparent;
      visibility: visible;
      z-index: 101;
      background-image: url("/assets/trendemon_inner_loader.svg");
    }
  }
  
  @-webkit-keyframes overlay-loader-spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes overlay-loader-spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }