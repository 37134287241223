@use "@angular/material" as mat;
@use "themes" as *;
@use "app-theme.scss" as *;
@import "assets/styles/icomoon";
@import "assets/styles/loader";
/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v105/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2)
    format("woff2");
}

@font-face {
  font-family: "Proxima Nova";
  src: url("assets/fonts/ProximaNova-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Proxima Nova Bold";
  src: url("assets/fonts/Proxima Nova Bold.otf") format("opentype");
}

html,
body {
  font-family: "Proxima Nova", "Helvetica Neue", sans-serif;
  margin: 0;
  background-color: $trd-app-page-bg;
}

* {
  outline: none !important;
}

label {
  margin-bottom: 0px; // override _reboot.scss
}

.block {
  display: block;
}

.error {
  color: mat.m2-get-color-from-palette($trd-app-theme-light-warn, text);
}

.error-border {
  border: 1px solid mat.m2-get-color-from-palette($trd-app-theme-light-warn, text);
  border-radius: 5px;
  display: inline-block;
  padding: 10px;
}

.mat-mdc-menu-panel.top-menu-dropdown {
  max-width: 450px;
  padding: 10px 0 15px;
}

.nested-menu-arrows-left button[mat-menu-item],
.nested-menu-arrows-left a[mat-menu-item] {
  padding-left: 32px;

  &.mat-menu-item-submenu-trigger::after {
    border-width: 5px 5px 5px 0;
    border-color: transparent currentColor transparent transparent;
    right: 0;
    left: 16px;
  }
}

.app-word-break-all {
  word-break: break-all;
}

.app-bold-font {
  font-family: "Proxima Nova Bold", "Helvetica Neue", sans-serif;
}

.app-message {
  display: flex;
  background-color: #716494;
  color: #ffffff;
  border-radius: 10px;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;

  .app-message-buttons {
    display: flex;
    gap: 5px;

    button {
      color: white;
      background: rgba(78, 38, 91, 0.44);
      font-size: 12px;
      border: none;
      border-radius: 8px;
      padding: 5px 10px;
      min-width: 45px;
    }

    button.scondary {
      background-color: rgba(255, 254, 254, 0.25);
    }
  }
}

/* Flags */

.app-flag {
  height: 25px;
  width: 33.33px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
}

.mat-menu-item .app-flag {
  vertical-align: middle;
  margin-right: 10px;
}

.app-flag-jp {
  background-image: url("assets/flags/jp_1.svg");
  border: solid 1px #efefef;
}

.app-flag-us {
  background-image: url("assets/flags/us_1.svg");
}

/* Side Nav */
.app-sidenav-header {
  text-align: center;
  padding-top: 20px;
  font-weight: bold;
  font-size: 0.85rem;
  color: #bbb;
}

.app-container > .mat-sidenav {
  min-width: 200px;
}

.app-container > .mat-sidenav .mat-nav-list fa-icon {
  min-width: 30px;
}

.app-sidenav-active-menu {
  font-weight: 500;
}

.app-sidenav-menu-button {
  transition: 300ms ease-in-out;
  transform: rotate(0deg);
}

.app-sidenav-menu-button.rotated {
  transform: rotate(180deg);
}

.app-sidenav-submenu {
  max-height: 0;
  overflow-y: hidden;
  transition: max-height 0.5s ease-out;
}

.app-sidenav-submenu.expanded {
  max-height: 500px;
  transition: max-height 0.5s ease-in;
}

.mat-nav-list .app-sidenav-submenu .mat-list-item {
  height: 38px;
}

.mat-nav-list .app-sidenav-submenu .mat-list-item .mat-list-item-content {
  font-size: 0.9rem;
  padding-left: 40px;
}

.app-sidenav-bullet {
  padding-right: 15px;
}

/* Page */

.app-page-header {
  margin-bottom: 20px;
}

.app-page-card.mat-card {
  padding: 25px;
}

/* Styled Page*/

.app-page-heading {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;

  &.small-bottom-margin {
    margin-bottom: 10px;
  }
}

.app-page-heading h2 {
  font-size: 30px;
  color: $trd-app-title-color;
  text-transform: capitalize;
  font-weight: 700;
}

.app-page-heading h2 img {
  margin-right: 10px;
  vertical-align: baseline;
}

.app-page-heading .mat-icon.back-btn {
  margin-top: 2px;
  margin-right: 10px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}

.app-page-heading-buttons > * {
  margin-left: 10px;
}

.app-rounded-btn {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.6px;
  white-space: nowrap;
  background-color: $trd-app-menu-primary-color;
  color: white;
  border: none;
  border-radius: 20px;
  height: 40px;
  padding: 0 25px;
  transition: 300ms ease-in-out;

  &:hover:enabled {
    background-color: #006ac6;
  }
  &:disabled {
    background-color: #7d8ea2;
  }
}

.app-secondary-rounded-btn {
  @extend .app-rounded-btn;
  background-color: white;
  color: $trd-app-menu-primary-color;
  border: solid 2px $trp-app-primary-color-light2;

  &:hover:enabled {
    background-color: $trp-app-primary-color-light2;
  }
  &:disabled {
    background-color: #cccccc;
  }
}

.app-small-text-btn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $trp-app-primary-color;
  background: transparent;
  border: 1px solid $trp-app-primary-color;
  border-radius: 20px;
  padding: 3px 8px;
  font-size: 12px;
  white-space: nowrap;
  transition: 300ms;

  &:hover {
    background-color: $trp-app-primary-color-light2;
  }

  &:disabled {
    background-color: #cccccc;
  }
}

.app-small-icon-text-btn {
  @extend .app-small-text-btn;
  padding: 3px 8px 3px 3px;

  .mat-icon {
    font-size: 16px;
    height: unset;
    width: 18px;
  }
}

.app-outline-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  color: $trp-app-primary-color;
  border: 1.5px solid $trp-app-primary-color;
  background: transparent;
  border-radius: 20px;
  height: 40px;
  padding: 0 20px;
  transition: 300ms;

  .mat-icon {
    font-size: 20px;
    height: unset;
    width: 18px;
  }

  &:hover {
    color: $trp-app-primary-color;
    background-color: $trp-app-primary-color-light2;
    text-decoration: none;
  }
}

.link-button {
  color: #007bff;
  cursor: pointer;

  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
}

.app-page-section {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 25px;
}

@media (max-width: 640px) {
  .app-page-heading h2 {
    font-size: 24px;
  }
}

.app-rounded-input {
  width: 260px;
  height: 40px;
  border-radius: 20px;
  border: none;
  padding: 0 20px;
  background-color: white;
  color: #585a6f;
  font-weight: bold;
}

/* Select Filled */

.select-filled-panel-small .mdc-list-item__primary-text {
  font-size: 14px;
}

.select-filled-panel-xsmall .mdc-list-item__primary-text {
  font-size: 12px;
}

/* Autocomplete */

app-autocomplete-filter,
app-autocomplete,
app-drop-down-filter {
  .mat-mdc-form-field-icon-suffix {
    align-self: flex-end;
  }
}

/* Date Picker */

.date-range-form-field {
  .mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-infix {
    width: 175px;
  }

  .mat-mdc-form-field-icon-suffix {
    align-self: flex-end;
  }

  .mat-datepicker-toggle {
    .mdc-icon-button {
      height: 24px;
      width: 24px;
      padding: 0;
      margin-bottom: 8px;
      overflow: hidden;

      svg {
        width: 16px;
      }
    }
  }
}

/* Filters */

.app-filters-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.app-filters-group .mat-form-field-infix,
.app-filters-right .mat-form-field-infix {
  font-weight: bold;
}

.app-filters-group .mat-select-value-text,
.app-filters-group .mat-input-element {
  color: #585a6f;
}

.app-filters-group .mat-form-field-label {
  color: #a5aebe;
}

.app-filters-left,
.app-filters-wrap {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
}

.app-filters-right {
  display: flex;
  flex-wrap: wrap;
  margin-left: 8px;
  gap: 8px;
  align-content: flex-start;
  justify-content: flex-end;
}

.filter-item .mat-mdc-form-field {
  .mat-mdc-text-field-wrapper {
    height: 50px;

    .mat-mdc-form-field-flex .mdc-floating-label {
      top: 36px;

      &.mdc-floating-label--float-above {
        top: 28px;
      }
    }
  }

  .mat-mdc-form-field-infix {
    .mat-mdc-select-arrow-wrapper {
      align-items: flex-end;

      .mat-mdc-select-arrow {
        height: 10px;
      }
    }

    .mat-mdc-input-element,
    .mat-mdc-select {
      font-weight: bold;
    }

    .mat-mdc-input-element::placeholder,
    .mat-mdc-select-placeholder {
      font-weight: normal;
    }
  }
}

.filter-item .mat-mdc-form-field.filter-multiselect .mat-mdc-text-field-wrapper {
  height: auto;
}

@media (max-width: 900px) {
  .app-filters-group {
    flex-direction: column-reverse;
  }
}

@media (max-width: 640px) {
  .app-filters-group {
    margin-bottom: 5px;
  }
}

@media (max-width: 440px) {
  .app-filters-left {
    flex-direction: column;
  }

  .app-filters-right {
    flex-direction: column;
    align-content: center;
  }
}

/* Sub Menu*/

.sub-menu {
  display: flex;
  overflow-x: auto;
  font-size: 16px;

  &.move-up {
    margin-top: -30px;
    margin-bottom: 20px;
  }
  &.move-up .sub-menu-item {
    margin-top: 0;
  }
}

.sub-menu.justify-center {
  justify-content: center;
}

.sub-menu-item,
.sub-menu-item:hover {
  margin: 10px 20px;
  color: #000000;
  cursor: pointer;
}

.sub-menu-item.active {
  color: mat.m2-get-color-from-palette($trd-app-theme-light-accent);
  text-decoration: underline;
}

.menu-third-line {
  margin-top: -45px;
  margin-bottom: 20px;
}

/* Stats */

.app-stats-box {
  display: flex;
  padding: 34px 30px;
}

.app-stats-item {
  flex: 1;
  padding: 14px 0;
  margin-right: 25px;
  border-right: 3px solid $trd-app-border-light-color;
}

.app-stats-item:last-child {
  margin-right: 0;
  border-right: none;
}

@media (max-width: 560px) {
  .app-stats-box {
    flex-direction: column;
  }

  .app-stats-item {
    margin-right: 0;
    border-right: none;
  }

  .app-stats-item + .app-stats-item {
    margin-top: 5px;
    border-top: 3px solid $trd-app-border-light-color;
  }
}

/* Table */

.app-table-wrapper table {
  border-radius: 10px 10px 0 0;
  width: 100%;
  overflow: hidden;
  transition: opacity 300ms;
}

.app-table-wrapper.app-table-loading table {
  opacity: 0.5;
}

.app-table-wrapper table .mat-mdc-header-cell,
.app-table-wrapper table th {
  color: #7d8ea2;
  background-color: #ebe7ff;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 15px;
}

.app-table-wrapper table .mat-mdc-header-row.app-table-top-header-row,
.app-table-wrapper table .mat-mdc-header-row.app-table-bottom-header-row {
  height: unset;
}
.app-table-wrapper table .mat-mdc-header-row.app-table-double-header-row {
  height: 65px;
}

.app-table-wrapper table .mat-mdc-header-cell.app-table-top-header-cell {
  border-bottom: none;
  padding-top: 15px;
  padding-bottom: 5px;
}

.app-table-wrapper table .app-table-row-align-top .mat-mdc-header-cell {
  vertical-align: top;
}

.app-table-wrapper table .mat-mdc-header-cell {
  font-weight: bold;
  vertical-align: bottom;
  padding-bottom: 10px;
  padding-right: 8px;
  line-height: normal;
}

.app-table-wrapper table .mat-mdc-header-cell .mat-sort-header-sorted,
.app-table-wrapper table .mat-mdc-header-cell .mat-sort-header-sorted .mat-sort-header-arrow {
  color: $table-header-sorted-color;
}

.app-table-wrapper table:not(.expandable):not(.no-alternate) .mat-row:nth-child(even),
.app-table-wrapper table:not(.expandable):not(.no-alternate) tr:nth-child(even) {
  background-color: $table-row-alternate-color;
}
.app-table-wrapper table:not(.expandable):not(.no-alternate) tr:nth-child(odd),
.app-table-wrapper table.expandable:not(.no-alternate) tr:nth-child(4n + 3):hover, // table row
.app-table-wrapper table.expandable:not(.no-alternate) tr:nth-child(2n):hover, // details row
.app-table-wrapper table:not(.expandable).no-alternate tr:hover {
  background-color: white;
}
.app-table-wrapper table.expandable .mat-mdc-row:nth-child(4n + 1) {
  background-color: $table-row-alternate-color;
}

.app-table-wrapper table .mat-mdc-row:last-child .mat-mdc-cell {
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}

.app-table-wrapper table .mat-mdc-cell,
.app-table-wrapper table .mat-mdc-cell .mdc-form-field,
.app-table-wrapper table td {
  font-size: 16px;
  font-weight: bold;
  color: $table-cell-color;
  vertical-align: middle;
  padding-right: 8px;
}

.app-table-wrapper.app-table-high-lines table .mat-mdc-cell,
.app-table-wrapper.app-table-high-lines table td {
  height: 90px;
}
.app-table-wrapper.app-table-medium-lines table:not(.expandable) .mat-row .mat-mdc-cell,
.app-table-wrapper.app-table-medium-lines table:not(.expandable) tr td {
  height: 70px;
}

.app-table-wrapper table .mat-mdc-header-cell.cell-header-center,
.app-table-wrapper table .mat-mdc-cell.cell-center {
  text-align: center;
}

.app-table-wrapper table .mat-mdc-header-cell.cell-header-center .mat-sort-header-container {
  justify-content: center;
}

.app-table-wrapper .mat-mdc-paginator .mat-mdc-paginator-container {
  min-height: 70px;
  justify-content: center;
}

.app-table-wrapper .mat-mdc-paginator .mat-mdc-paginator-container,
.app-table-wrapper .mat-mdc-paginator .mat-mdc-select-value {
  font-size: 16px;
  font-weight: bold;
  color: $table-paginator-color;
}

.app-table-wrapper .mat-mdc-paginator .mat-mdc-paginator-icon {
  width: 28px;
  height: 28px;
}

.app-table-wrapper .mat-mdc-paginator-page-size .mat-mdc-select-trigger {
  font-size: 16px;
}

.app-table-wrapper table .mat-mdc-header-cell.position-score-left .mat-sort-header-content {
  flex-direction: column;
  align-items: flex-start;
}

.app-table-wrapper table .mat-mdc-header-cell.position-score-middle .mat-sort-header-content {
  flex-direction: column;
  white-space: nowrap;
}

.app-table-wrapper table .mat-mdc-header-cell.position-score-right .mat-sort-header-content {
  flex-direction: column;
  align-items: flex-end;
}

.app-table-wrapper table .mat-mdc-header-cell.position-score-left,
.app-table-wrapper table .mat-mdc-header-cell.position-score-right,
.app-table-wrapper table .mat-mdc-header-cell.position-score-middle .nurture-th {
  font-size: 11px;
  padding-left: 3px;
}

.app-table-wrapper table .mat-mdc-header-cell.position-score-middle {
  position: relative;
  overflow: inherit;
}

.position-th {
  position: absolute;
  top: -26px;
}

.app-table-wrapper table .mat-mdc-header-cell.position-score-left {
  padding-left: 5px;
  padding-right: 0;
}

.app-table-wrapper table .mat-mdc-header-cell.position-score-right {
  padding-right: 0 !important;
}

.app-table2-wrapper {
  table {
    width: 100%;

    th.mat-mdc-header-cell {
      font-family: "Proxima Nova Bold", "Helvetica Neue", sans-serif;
      color: #2e404c;
      background-color: #e1f0ff;
    }

    th:not(:first-child),
    td:not(:first-child) {
      padding-left: 20px;
    }

    tr.mat-mdc-row:nth-child(even),
    tr.mat-mdc-row:nth-child(even):hover {
      background-color: #f3f7fb;
    }

    tr.mat-mdc-row:nth-child(odd):hover {
      background-color: white;
    }

    td {
      font-size: 12px;
      border: none;
    }

    .mat-mdc-header-cell.cell-header-center,
    .mat-mdc-cell.cell-center {
      text-align: center;
    }

    .mat-mdc-header-cell.cell-header-center .mat-sort-header-container {
      justify-content: center;
    }
  }

  .mat-mdc-paginator .mat-mdc-paginator-container {
    justify-content: center;
  }

  .mat-mdc-paginator .mat-mdc-paginator-container,
  .mat-mdc-paginator .mat-mdc-select-value {
    font-size: 16px;
    font-weight: bold;
    color: $table-paginator-color;
  }

  .mat-mdc-paginator .mat-mdc-paginator-icon {
    width: 28px;
    height: 28px;
  }

  .mat-mdc-paginator-page-size .mat-mdc-select-trigger {
    font-size: 16px;
  }
}

.mat-column-landing,
.mat-column-nurture,
.mat-column-conversion {
  width: 65px;
}

.app-table-no-records {
  font-weight: bold;
  background-color: white;
  text-align: center;
  padding: 10px 0;
}

/* Rule Engine */

.app-rule-engine-action {
  color: $trd-rule-engine-action-color;
  font-weight: bold;
  font-size: 14px;
}

.app-rule-engine-action-icon {
  color: $trd-rule-engine-action-color;
  font-size: 20px;
}

/* Form Field */

.form-field-horizontal {
  display: flex;
  align-items: baseline;
  gap: 15px;
  max-width: 650px;
}

.mat-mdc-form-field.mat-form-field-appearance-fill:not(.form-field-textarea) {
  &:hover .mat-mdc-form-field-focus-overlay,
  &.mat-focused .mat-mdc-form-field-focus-overlay {
    opacity: 0;
  }

  .mat-mdc-text-field-wrapper {
    background-color: transparent;
    padding: 0 2px;
  }

  .mat-mdc-form-field-error-wrapper,
  .mat-mdc-form-field-hint-wrapper {
    padding-left: 0px;
  }
}

.mat-mdc-form-field.mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-input:not(.form-field-textarea):not(
    .form-field-textarea-plain
  ),
.mat-mdc-form-field.mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-select:not(
    .mat-mdc-paginator-page-size-select
  ) {
  .mat-mdc-text-field-wrapper {
    height: 52px;

    .mat-mdc-form-field-flex .mdc-floating-label {
      top: 36px;

      &.mdc-floating-label--float-above {
        top: 30px;
      }
    }
  }
}

//    form field - small-height
.mat-mdc-form-field.mat-form-field-appearance-fill:not(.form-field-textarea):not(
    .form-field-textarea-plain
  ).small-height {
  .mat-mdc-text-field-wrapper {
    height: 36px;
  }

  .mat-mdc-form-field-infix {
    padding-top: 8px;
  }
}

//  formfield - no underline
.mat-mdc-form-field.no-underline .mdc-line-ripple {
  display: none;
}

/* Inputs */

.small-inputs-12 .mat-mdc-form-field-infix,
.small-inputs-12 mat-label,
.small-inputs-12 mat-select-trigger,
.small-inputs-12 .mat-mdc-select-min-line,
.small-inputs-12 .mat-mdc-form-field-input-control.mat-mdc-input-element,
.small-inputs-12 app-textbox-filled input
{
  font-size: 12px;
}

.small-inputs-12 app-select-filled .selected-container .selected-option
{
  font-size: 12px !important;
}

/* Select */

.no-line .mat-form-field-underline {
  display: none;
}

.select-wrap .mat-select-value-text {
  white-space: normal;
  line-height: 1.3;
}

.mat-mdc-optgroup-label mat-label {
  font-size: 14px;
  color: #0000008a;
}

.select-panel-xsmall .mat-mdc-option .mdc-list-item__primary-text {
  font-size: 12px;
}

/* Checkbox */

mat-checkbox.no-left-margin {
  margin-left: -11px;
}

mat-checkbox.small-text-12 label {
  font-size: 12px;
  padding: 0;
}

.mdc-checkbox__checkmark-path {
  color: #ffffff;
}

/* Radio Button*/

.mat-mdc-radio-button .mdc-form-field .mdc-radio {
  padding: 7px 6px 7px 0;
}

mat-radio-group.vertical mat-radio-button {
  display: block;
}

mat-radio-button.radio-text-clickable {
  cursor: pointer;

  label {
    cursor: pointer;
  }
}

/* Dialog */

.mat-mdc-dialog-actions.mdc-dialog__actions {
  padding: 8px 24px 18px 24px;
  justify-content: flex-end;
  gap: 10px;
}

.dialog-thin-padding .mat-mdc-dialog-container {
  position: relative;
  padding: 25px 10px 10px 10px;
}

/* Stepper */

.app-stepper .mat-step-header .mat-step-icon-selected,
.app-stepper .mat-step-header .mat-step-icon-state-done {
  background-color: $trd-app-secondary-color;
}

.app-stepper .mat-step-header .mat-step-icon-state-edit {
  background-color: rgba(0, 0, 0, 0.54);
}

.app-stepper .mat-step-header .mat-step-label {
  font-size: 20px;
  font-weight: bold;
  margin-left: 5px;
}

.app-stepper .mat-step-icon {
  height: 40px;
  width: 40px;
}

.app-stepper .mat-step-icon-content {
  font-family: "Proxima Nova Bold", "Helvetica Neue", sans-serif;
}

.app-stepper .mat-vertical-content-container {
  margin-left: 44px;
}

/* Expansion panel */

.app-expansion-panel .mat-expansion-panel-header-title {
  font-size: 18px;
}

// .accordion-add-spacing
.mat-accordion.accordion-add-spacing > .mat-expansion-panel:not(.mat-expanded),
.mat-accordion.accordion-add-spacing > .mat-expansion-panel:not(.mat-expansion-panel-spacing) {
  margin-bottom: 20px;
}
.expansion-add-spacing.mat-expansion-panel:not(.mat-expanded),
.expansion-add-spacing.mat-expansion-panel:not(.mat-expansion-panel-spacing) {
  margin-bottom: 20px;
}

// expansion-no-padding
.mat-expansion-panel.expansion-no-padding.element-panel > .mat-expansion-panel-content > .mat-expansion-panel-body {
  padding-left: 0;
  padding-right: 0;
}

// expansion-no-shadow
.mat-expansion-panel.expansion-no-shadow {
  box-shadow: none !important;
}

/* Multi Select Chips */

app-multiselect-autocomplete.multiselect-filled {
  mat-chip-grid {
    background-color: #f8f7ff;
    border: none;
    width: 100%;
    border-radius: 30px;
    padding: 7px 7px 7px 18px;
    cursor: pointer;
  }

  .mdc-evolution-chip-set__chips {
    align-items: center;
  }

  .mdc-text-field__input::-webkit-input-placeholder {
    opacity: 1;
  }
  .mdc-text-field__input::placeholder {
    opacity: 1;
  }
}

/* General */

.no-selected-outline {
  outline: none !important;
}

.full-width {
  width: 100%;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-filler {
  flex: 1;
}

.flex-content-center {
  justify-content: center;
}

.flex-item-vertical-center {
  align-self: center;
}

.text-capitalize {
  text-transform: capitalize;
}

.no-wrap {
  white-space: nowrap;
}

// cell spinner
.cell-spinner-wrap {
  display: inline-block;
}
.cell-spinner.default-spinner circle {
  stroke: #666;
}
.cell-spinner.white-spinner circle {
  stroke: #fff;
}

// white spinner
.white-spinner.mat-progress-spinner circle,
.white-spinner.mat-spinner circle {
  stroke: #ffffff !important;
}

// tooltip
.mat-tooltip,
.mdc-tooltip {
  font-size: 12px !important;
  white-space: pre-line;
}
.tooltip-break-lines {
  white-space: pre-line;
}

// button toggle group
.button-toggle-heigh-md .mat-button-toggle .mat-button-toggle-button .mat-button-toggle-label-content {
  line-height: 36px;
}

// autocomplete panel
.app-autocomplete-panel {
  max-width: 500px !important;
}

.line {
  background: #ccc none repeat scroll 0 0;
  height: 1px;
  margin: 40px auto;
  position: relative;
  width: 70%;
}

.line div {
  background: #fff none repeat scroll 0 0;
  color: #3d4051;
  font-size: 18px;
  left: 50%;
  margin-left: -100px;
  position: absolute;
  text-align: center;
  top: -14px;
  width: 200px;
}

.media {
  display: flex;
  justify-content: center;
}

.media img {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  border: 1px solid #f1f1f1;
  object-fit: cover;
}

.margin-vertical-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.margin-horizontal-4 {
  margin-left: 4px;
  margin-right: 4px;
}

.margin-vertical-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.margin-horizontal-10 {
  margin-left: 10px;
  margin-right: 10px;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}

.padding-vertical-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.padding-horizontal-4 {
  padding-left: 4px;
  padding-right: 4px;
}

.padding-horizontal-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.padding-10 {
  padding: 10px;
}

.margin-10 {
  margin: 10px;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.font-italic {
  font-style: italic;
}
mat-card.flex-container,
.flex-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.flex-container-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.spacer {
  flex: 1 1 auto;
}
.full-width-table {
  width: 100%;
}

.total-statistics-blocks-item {
  border-right: 1px solid;
  border-color: $line-color;
}

.status-on {
  color: $status-on;
}
.status-off {
  color: $status-off;
}
.card-page-title {
  background: none !important;
}

.back-to-page {
  text-decoration: none;
  color: $back-to-page;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.back-to-page mat-icon {
  margin-right: 5px;
}
.back-to-page:hover {
  text-decoration: none;
  color: $back-to-page;
  cursor: pointer;
  opacity: 0.8;
}

.wrap-anywhere {
  overflow-wrap: anywhere;
}

.rightSideFilter {
  float: right;
  clear: both;
}
.mat-tab-label-active {
  color: mat.m2-get-color-from-palette($trd-app-theme-light-accent, text);
}

.app-add-edit-buttons {
  display: flex;
  justify-content: flex-end;
}

.app-add-edit-buttons-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.app-add-edit-buttons-left .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 36px;
}

.settings-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

/* table actions */
.table-actions mat-icon {
  color: $action-icon-color;
  cursor: pointer;
  width: auto;
}

.table-actions mat-icon:hover {
  color: $table-header-sorted-color;
}

.table-actions .disabled,
.table-actions .disabled:hover {
  color: #e0e0e0;
  cursor: default;
}

.dropColumn mat-icon {
  cursor: grab;
  color: $action-icon-color;
}

/* table actions */

.notif-msg {
  color: #bbb;
}

.warn {
  color: $status-unknown;
}
.info {
  color: mat.m2-get-color-from-palette($trd-app-theme-light-primary, text);
}

$topmenu-color: mat.m2-get-color-from-palette($backgroundTheme, "card");
// .mat-toolbar-single-row {
//   background-color: $topmenu-color;
// }

.mat-toolbar.mat-primary {
  background-color: $topmenu-color;
  color: $trd-app-theme-text-primary;
}

.menu-arrow {
  color: mat.m2-get-color-from-palette($trd-app-theme-light-accent, text);
}

/*mobile: tables to cards*/
@media screen and (max-width: 600px) {
  .mt-content {
    padding-left: 2%;
  }
  .mat-table:not(.no-shrink) {
    border: 0;
    vertical-align: middle;

    .mat-header-cell {
      border: 5px solid;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr.mat-header-row {
      height: auto;
    }

    .mat-row {
      border-bottom: 5px solid #ddd;
      display: block;
    }

    tr.mat-row {
      height: auto;
    }

    .mat-cell {
      border-bottom: 1px solid #ddd;
      display: block;
      font-weight: bold;
      margin-bottom: 2%;
      padding: 2%;
    }

    .mat-cell:before {
      content: attr(data-label) " : ";
      float: left;
      font-weight: normal;
      padding: 0 2px 0 5px;
    }

    .mat-cell:last-child {
      border-bottom: 0;
    }

    .mat-cell:first-child {
      margin-top: 2%;
    }

    td.mat-cell:first-of-type {
      padding-left: 2%;
    }
  }
}

.disabled-non-permitted-module {
  opacity: 0.6;
}

div.insightsHidden,
app-table-paginator.insightsHidden,
table.insightsHidden tbody {
  display: none !important;
}

.success-text {
  color: $trend-up-color;
}
.failed-text {
  color: $trend-down-color;
}

button.mdc-fab.mat-light {
  transform: scale(0.5);
  background-color: $table-header-sorted-color;
  color: white;
}

td *[routerLink] {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

app-total-statistics[routerLink],
.linkedBox {
  cursor: pointer;
  &:hover .details .title {
    color: #8e78fb !important;
  }
}

.app-stats-item.compare_1 .icon-wrapper img,
.stats-item.compare_1 .icon-wrapper img,
.dot.compare_1 {
  filter: saturate(10);
}

.app-stats-item.compare_2 .icon-wrapper img,
.stats-item.compare_2 .icon-wrapper img,
.dot.compare_2 {
  filter: hue-rotate(320deg);
}

.app-stats-item.compare_3 .icon-wrapper img,
.stats-item.compare_3 .icon-wrapper img,
.dot.compare_3 {
  filter: hue-rotate(250deg);
}

.colored-icon,
.mat-button-toggle-checked .mat-button-toggle-button.mat-focus-indicator {
  color: $trp-app-primary-color;
}

.hl-text .mat-option-text {
  color: $trd-app-menu-primary-color;
  font-weight: bold;
}

.ck.ck-editor__main > .ck-editor__editable.ck-editor__editable_inline {
  background-color: #777777;
}

/* dashboard abm funnel */
.svg-funnel-js {
  display: inline-block;
  position: relative;
  padding-top: 20px;

  .svg-funnel-js__labels {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .label__value {
    font-size: 24px;
    color: #657281;
    line-height: 18px;
    margin-bottom: 6px;
  }

  .label__title {
    font-size: 12px;
    font-weight: bold;
    color: #657281;
  }

  &:not(.svg-funnel-js--vertical) .label__title,
  &:not(.svg-funnel-js--vertical) .label__value {
    display: none;
  }

  .label__percentage {
    font-size: 0px;
    color: #657281;
  }

  &:not(.na) .label__percentage {
    font-size: 14px;
    font-weight: bold;
  }

  &:not(.svg-funnel-js--vertical) .label__percentage {
    background: white;
    position: absolute;
    left: -25px;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    line-height: 50px;
    text-align: center;
    top: 50%;
    margin-top: -15px;
  }

  &:not(.svg-funnel-js--vertical) .svg-funnel-js__label:not(:first-child) {
    border-left: 3px solid #e2e0f5;
  }

  &.svg-funnel-js--vertical .svg-funnel-js__label:not(:first-child) {
    border-top: 3px solid #e2e0f5;
  }

  &.na .label__percentage {
    background: white url("assets/dashboard/na_small.png") center center no-repeat;
  }

  .svg-funnel-js__label:first-child .label__percentage {
    display: none;
  }

  &.svg-funnel-js--vertical {
    padding-left: 120px;
    padding-right: 16px;
  }

  &.svg-funnel-js--vertical .svg-funnel-js__label {
    padding-top: 24px;
  }
}

.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__labels {
  flex-direction: column;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label {
  flex: 1 1 0;
  position: relative;
}

@media (max-width: 640px) {
  .svg-funnel-js.na .label__value {
    display: none;
  }
}

// hide number input spinner

/* Chrome, Safari, Edge, Opera */
input.hide-spinner::-webkit-outer-spin-button,
input.hide-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input.hide-spinner[type="number"] {
  -moz-appearance: textfield;
}

.grouped .details {
  min-height: auto !important;
}

@media only screen and (max-width: 480px) {
  .grouped .details {
    text-align: center;
  }
}

.mat-mdc-option .mdc-list-item__primary-text {
  padding-bottom: 7px;
  line-height: 1.5 !important;
}

.checkbox-right .mdc-form-field {
  flex-direction: row-reverse;
}

.checkbox-right .mdc-form-field > label {
  padding-left: 0;
  font-size: 18px;
  padding-right: 10px;
}

.mdc-text-field {
  overflow: visible !important;
}

// funnel graph

.fg:not(.fg--vertical) .fg-label__title,
.fg:not(.fg--vertical) .fg-label__value {
    display: none;
}

.fg .fg-label__percentage {
    color: #657281;
}

.fg:not(.na) .fg-label__percentage {
    font-size: 14px;
    font-weight: 700;
}

.fg .fg-label:first-child .fg-label__percentage {
    display: none;
}

.fg:not(.fg--vertical) .fg-label__percentage {
    background: white;
    position: absolute;
    left: -25px;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    line-height: 50px;
    text-align: center;
    top: 50%;
}

.fg:not(.fg--vertical) .fg-label:not(:first-child) {
    border-left: 3px solid #e2e0f5;
}

.filter-multiselect .mat-mdc-form-field-infix{
  padding-top: 25px !important;
  padding-bottom: 0px !important;
}

.filter-multiselect .mat-mdc-form-field-infix{
  min-height: 52px !important;
}

.filter-multiselect .mat-mdc-standard-chip .mdc-evolution-chip__action--primary, .filter-multiselect .mat-mdc-standard-chip .mat-mdc-chip-action-label{
  overflow: hidden !important;
}
