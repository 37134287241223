@font-face {
  font-family: "icomoon";
  src: url("/assets/fonts/icomoon.eot?7w7vcf");
  src: url("/assets/fonts/icomoon.eot?7w7vcf#iefix") format("embedded-opentype"),
    url("/assets/fonts/icomoon.ttf?7w7vcf") format("truetype"), url("/assets/fonts/icomoon.woff?7w7vcf") format("woff"),
    url("/assets/fonts/icomoon.svg?7w7vcf#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-google3:before {
  content: "\ea8a";
}
.icon-facebook:before {
  content: "\ea90";
}
