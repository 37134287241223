$journey-overview-main-color: #cccccc;
$journey-session-breakdown-main-color: #cccccc;
$journey-overview-background-color: #ffffff;
$journey-session-breakdown-background-color: #ffffff;

$status-on: #66a802;
$status-off: #bb4b1c;
$status-unknown: #db8d59;
$line-color: #ccc;
$back-to-page: rgba(0, 0, 0, 0.87);
$action-icon-color: #aaaaaa;
$trd-app-theme-text-primary: $back-to-page;
$border_color: #e0e0e0;
$hover_background: #fafafa;
$error-color: #e57373;

$trd-app-menu-break: 1200px;
$trd-app-wide-menu-break: 1350px;

$trd-app-page-bg: #f4f7f6;
$trd-app-menu-primary-color: #1d91ff;
$trp-app-primary-color: #1c91ff;
$trp-app-primary-color-light: #d2e9ff;
$trp-app-primary-color-light2: #ddefff;
$trd-app-secondary-color: #f53e72;
$trd-app-secondary-color-light: #f7e7eb;
$trd-app-title-color: #2f3e4f;
$trd-app-border-light-color: #f9f5fe;

$trd-rule-engine-action-color: $trd-app-menu-primary-color;

$trend-up-bg: #d7ede3;
$trend-up-color: #7bc5a2;
$trend-down-bg: #fdd1e0;
$trend-down-color: #f7497a;
$trend-no-change-bg: #e6e6e6;
$trend-no-change-color: #888888;

$btn-round-bg: #c9e3f8;
$btn-round-color: $trp-app-primary-color;
$btn-round-hover-bg: $trp-app-primary-color;
$btn-round-hover-color: #ffffff;

$table-header-bg: #ebe7ff;
$table-header-color: #7d8ea2;
$table-header-sorted-color: #907afb;
$table-row-alternate-color: #f8f7ff;
$table-cell-color: #464963;
$table-paginator-color: #8185a5;

$table-cell-tag-bg: $table-header-bg;
$table-cell-tag-color: $table-header-color;
$table-cell-error-color: #ff0000;